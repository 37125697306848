<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-md-6 col-lg-4">
          <label
            for="search"
          >
            Поиск:
          </label>
          <b-form-input
            id="search"
            v-model="search"
          />
        </div>
      </div>
      <b-button
        class="mb-2"
        variant="primary"
        @click.prevent="showAddModal = true"
      >
        Добавить услугу
      </b-button>
      <div
        v-if="services.length"
        class="accordion"
        role="tablist"
      >
        <draggable
          animation="200"
          ghost-class="ghost-sort"
          :list="services"
          @change="changedSorting"
        >
          <div
            v-for="service in searchedServices"
            :key="service.id"
            class="accordion__item "
          >
            <div
              v-b-toggle="`accordion-${service.id}`"
              role="tab"
              class="accordion-label"
            >
              <div class="accordion-label__content">
                <div class="row align-items-center w-100">
                  <div class="col-12 col-lg-4">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="FolderIcon"
                        size="21"
                        class="mr-1"
                      />

                      {{ service.name }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <code>{{ service.slug }}</code>
                  </div>
                  <div class="col-12 col-lg-2">
                    <div
                      class="d-flex align-items-center"
                      @click.stop
                    >
                      <b-form-checkbox
                        :checked="service.is_hidden"
                        @change="updateHidden(service)"
                      >
                        Скрыто
                      </b-form-checkbox>
                    </div>
                  </div>
                  <div class="col-12 col-lg-2">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        @click.stop="editedServiceId = service.id"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="12"
                        />
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="btn-icon"
                        @click.stop="deletedServiceId = service.id"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="12"
                        />
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-collapse
              :id="`accordion-${service.id}`"
              accordion="my-accordion"
              role="tabpanel"
              @show="currentServiceId = service.id"
            >
              <sub-services
                v-if="currentServiceId === service.id"
                :key="service.id"
                :service-parent-id="service.id"
              />
            </b-collapse>
          </div>
        </draggable>
      </div>
      <div v-else>
        Пока нет ни одной услуги
      </div>
    </b-card>
    <add-service-popup
      v-if="showAddModal"
      :service-parent-id="null"
      @added="addService"
      @hidden="showAddModal = false"
    />
    <delete-service-popup
      v-if="deletedServiceId"
      :service-id="deletedServiceId"
      @deleted="deleteService"
      @hidden="deletedServiceId = null"
    />
    <edit-service-popup
      v-if="editedServiceId"
      :service-id="editedServiceId"
      @updated="updateService"
      @hidden="editedServiceId = null"
    />
  </b-overlay>
</template>

<script>
import Draggable from 'vuedraggable'
import AdminService from '@/api/http/models/admin/service/AdminService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BCollapse, BButton, BOverlay, BFormInput, BFormCheckbox, VBToggle,
} from 'bootstrap-vue'
import AddServicePopup from './AddServicePopup.vue'
import SubServices from './SubServices.vue'
import DeleteServicePopup from './DeleteServicePopup.vue'
import EditServicePopup from './EditServicePopup.vue'

export default {
  components: {
    BCard,
    BCollapse,
    BButton,
    BOverlay,
    BFormInput,
    BFormCheckbox,
    SubServices,
    AddServicePopup,
    DeleteServicePopup,
    EditServicePopup,
    Draggable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: true,
      showAddModal: false,
      services: [],
      currentServiceId: null,
      deletedServiceId: null,
      editedServiceId: null,
      search: '',
    }
  },
  computed: {
    searchedServices() {
      return this.services.filter(service => {
        const searchValue = this.search.toLowerCase()
        const name = service.name && service.name.toLowerCase().indexOf(searchValue) !== -1
        const slug = service.slug && service.slug.toLowerCase().indexOf(searchValue) !== -1
        return name || slug
      })
    },
  },
  async created() {
    const response = await AdminService.getAll(null)

    if (response.data) {
      this.services = response.data
      this.showLoading = false
    }
  },
  methods: {
    async changedSorting() {
      const services = this.services.map((service, index) => ({
        id: service.id,
        order: index + 1,
      }))

      const response = await AdminService.sort({
        parent_id: null,
        services,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Услуги отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
    addService(service) {
      this.services.push(service)
    },
    deleteService(serviceId) {
      const index = this.services.findIndex(el => el.id === serviceId)
      this.services.splice(index, 1)
    },
    updateService(service) {
      const index = this.services.findIndex(el => el.id === service.id)
      this.services.splice(index, 1, service)
    },
    async updateHidden(service) {
      const response = await AdminService.update(service.id, {
        ...service,
        ...{ is_hidden: !service.is_hidden },
      })

      if (response.status === 'success' && response.data) {
        this.updateService(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У сервиса «${service.name}» обновлена скрытость`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
