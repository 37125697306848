<template>
  <b-modal
    id="edit-service-popup"
    :visible="visible"
    size="md"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование услуги «${currentService.name}»?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-12">
          <b-form-group
            label="Обложка услуги"
          >
            <input-file
              v-model="currentService.cover_image_key"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Иконка услуги"
          >
            <input-file
              v-model="currentService.svg_icon"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Название"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="currentService.name"
              v-validate="'required'"
              name="name"
            />
            <small
              v-show="errors.has('name')"
              class="text-danger"
            >{{ errors.first('name') }}</small>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Slug (ЧПУ)"
            label-for="slug"
          >
            <b-form-input
              id="slug"
              v-model="currentService.slug"
              name="slug"
            />
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group>
            <b-form-checkbox
              id="is-hidden"
              v-model="currentService.is_hidden"
              name="is-hidden"
            >
              Скрыть
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdminService from '@/api/http/models/admin/service/AdminService'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import InputFile from '@core/components/input/InputFile.vue'

import TranslitRusToLat from '../../../@core/utils/TranslitRusToLat'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    InputFile,
  },
  mixins: [mixinErrorsParse],
  props: {
    serviceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      currentService: {
        name: '',
        slug: '',
        is_hidden: false,
        cover_image_key: '',
        svg_icon: '',
      },
    }
  },
  async created() {
    const response = await AdminService.getOne(this.serviceId)

    if (response.data) {
      this.currentService = response.data
      this.currentService.cover_image_key = response.data.cover_image && response.data.cover_image.key
      this.currentService.svg_icon = response.data.svg_icon && response.data.svg_icon.key
      this.visible = true
    }
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.currentService.slug = TranslitRusToLat(this.currentService.slug)

      if (this.currentService.name && !this.currentService.slug) {
        this.currentService.slug = TranslitRusToLat(this.currentService.name)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await AdminService.update(this.serviceId, this.currentService)

        if (response.status === 'success' && response.data) {
          this.$emit('updated', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Услуга изменена',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-service-popup')
          })
        } else {
          if (response.errors) {
            this.mixinErrorsParse(response.errors)
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при изменении услуги',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
