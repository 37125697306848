<template>
  <div>
    <div class="py-3 pl-3">
      <b-overlay
        :show="showLoading"
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      />
      <div class="h4 mb-2">
        Подкатегории
      </div>
      <ul
        v-if="services.length"
        ref="container"
        class="subitems-list"
      >
        <draggable
          animation="200"
          ghost-class="ghost-sort"
          :list="services"
          @change="changedSorting"
        >
          <li
            v-for="service in services"
            :key="service.id"
          >
            <div class="subitem">
              <div class="subitem__content">
                <div class="subitem__title">
                  <div class="row align-items-center">
                    <div class="col-4">
                      {{ service.name }}
                    </div>
                    <div class="col-4">
                      <code>{{ service.slug }}</code>
                    </div>
                    <div class="col-2">
                      <div
                        class="d-flex align-items-center"
                        @click.stop
                      >
                        <b-form-checkbox
                          :checked="service.is_hidden"
                          @change="updateHidden(service)"
                        >
                          Скрыто
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subitem__actions">
                <b-button
                  variant="outline-primary"
                  class="btn-icon mr-1"
                  @click.stop="editedServiceId = service.id"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                  />
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="btn-icon"
                  @click.stop="deletedServiceId = service.id"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="12"
                  />
                </b-button>
              </div>
            </div>
          </li>
        </draggable>
      </ul>
      <div v-else>
        У этой услуги пока нет подкатегорий
      </div>
      <b-button
        class="mt-2"
        variant="outline-primary"
        @click.prevent="showAddModal = true"
      >
        Добавить услугу
      </b-button>
    </div>
    <add-service-popup
      v-if="showAddModal"
      :service-parent-id="serviceParentId"
      title="Добавление подуслуги"
      @added="addService"
      @hidden="showAddModal = false"
    />
    <delete-service-popup
      v-if="deletedServiceId"
      :service-id="deletedServiceId"
      @deleted="deleteService"
      @hidden="deletedServiceId = null"
    />
    <edit-service-popup
      v-if="editedServiceId"
      :service-id="editedServiceId"
      @updated="updateService"
      @hidden="editedServiceId = null"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import {
  BOverlay, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import AdminService from '@/api/http/models/admin/service/AdminService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteServicePopup from './DeleteServicePopup.vue'
import AddServicePopup from './AddServicePopup.vue'
import EditServicePopup from './EditServicePopup.vue'

export default {
  components: {
    BOverlay,
    BButton,
    BFormCheckbox,
    DeleteServicePopup,
    AddServicePopup,
    EditServicePopup,
    Draggable,
  },
  props: {
    serviceParentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showLoading: true,
      showAddModal: false,
      services: [],
      editedServiceId: null,
      deletedServiceId: null,
    }
  },
  async created() {
    const response = await AdminService.getAll(this.serviceParentId)

    if (response.data) {
      this.showLoading = false
      this.services = response.data
    }
  },
  methods: {
    async changedSorting() {
      const services = this.services.map((service, index) => ({
        id: service.id,
        order: index + 1,
      }))

      const response = await AdminService.sort({
        parent_id: this.serviceParentId,
        services,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Услуги отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
    addService(service) {
      this.services.push(service)
    },
    updateService(service) {
      const index = this.services.findIndex(el => el.id === service.id)
      this.services.splice(index, 1, service)
    },
    deleteService(serviceId) {
      const index = this.services.findIndex(el => el.id === serviceId)
      this.services.splice(index, 1)
    },
    async updateHidden(service) {
      const response = await AdminService.update(service.id, {
        ...service,
        ...{ is_hidden: !service.is_hidden },
      })

      if (response.status === 'success' && response.data) {
        this.updateService(response.data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `У сервиса «${service.name}» обновлена скрытость`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка при обновлении',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
