import HTTPService from '@/api/http/HTTPService'
import ServiceInterface from './ServiceInterface'
import ServiceSortInterface from './ServiceSortInterface'

export default {
  getAll: (parentId: number | null) => HTTPService.get(`admin/service${parentId ? `?parent_id=${parentId}` : ''}`),
  add: (payload: ServiceInterface) => HTTPService.post('admin/service', payload),
  sort: (payload: ServiceSortInterface) => HTTPService.post('admin/service/reorder', payload),
  getOne: (serviceId: number) => HTTPService.get(`admin/service/${serviceId}`),
  update: (serviceId: number, payload: ServiceInterface) => HTTPService.put(`admin/service/${serviceId}`, payload),
  delete: (serviceId: number) => HTTPService.delete(`admin/service/${serviceId}`),
}
